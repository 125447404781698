<template>
  <div class="affiche-page">
    <!-- 招聘公告页面 -->
    <div class="affiche-top">
      <span class="navigation-btn">首页</span>
      <span class="arrow-icon"></span>
      <span class="title">招聘公告</span>
    </div>

    <!-- 主要内容 -->
    <div class="main-content">
      <!-- 侧边栏 -->
      <div class="left-sidebar">
        <div
          :class="`btn-item ${i === activeIndex ? 'pink' : item.color}`"
          v-for="(item, i) in sidebarList"
          :key="i"
          @click="selected(i)"
        >
          {{ item.text }}
        </div>
      </div>
      <!-- 右边分页 、 搜索 -->
      <div class="rigth-paging">
        <!-- 标题 -->
        <h4 class="contact-title">{{ contactTitle }}</h4>

        <!-- 搜索框 -->
        <div class="search-box">
          <input
            type="text"
            placeholder="请输入关键字搜索相关公告"
            class="input-btn"
          />
          <div class="search-btn">搜索</div>
        </div>
        <component :is="componentName" />
      </div>
    </div>
  </div>
</template>

<script>
import announcementList from "./components/announcementList.vue";
export default {
  name: "Affiche",
  components: {
    announcementList,
  },
  data() {
    return {
      // 动态组件名
      componentName: "announcementList",
      // 侧边栏列表
      sidebarList: [
        {
          text: "招聘公告",
          color: "red",
        },
        {
          text: "事业单位招聘公告",
          color: "white",
        },
        {
          text: "其它招聘公告",
          color: "white",
        },
        {
          text: "网上报名",
          color: "red",
        },
        {
          text: "事业单位报名",
          color: "white",
        },
        {
          text: "其它网上报名",
          color: "white",
        },
        {
          text: "准考证打印",
          color: "red",
        },
        {
          text: "成绩查询",
          color: "red",
        },
        {
          text: "相关通知",
          color: "red",
        },
        {
          text: "事业单位相关通知",
          color: "white",
        },
        {
          text: "其他相关通知",
          color: "white",
        },
        {
          text: "聘用公示",
          color: "red",
        },
        {
          text: "事业单位聘用公示",
          color: "white",
        },
        {
          text: "其他聘用公示",
          color: "white",
        },
        {
          text: "考试报名延长时间",
          color: "red",
        },
      ],
      // 侧边栏高亮下标
      activeIndex: 0,

      // 右边内容显示标题
      contactTitle: "招聘公告",
    };
  },
  methods: {
    // 侧边栏 移上显示高亮
    // mouseover(i) {
    //   this.activeIndex = i;
    // },
    selected(i) {
      this.activeIndex = i;
    },
  },
};
</script>

<style scoped>
.affiche-page {
  width: 100%;
  background-color: #ffffff;
}

.affiche-top {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 16px 0px;
}

.navigation-btn {
  width: 32px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  opacity: 1;
}

.arrow-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(../../assets/images/right-arrow.png) no-repeat 100% / 100%;
  margin: 0px 12px;
}

.title {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #e01811;
  opacity: 1;
}

.main-content {
  width: 100%;
  height: 807px;
  display: flex;
}

.left-sidebar {
  width: 20%;
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 32px;
  box-sizing: border-box;
  margin-right: 32px;
  user-select: none;
}

.btn-item {
  height: 40px;
  color: #333333;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.red {
  color: #ffffff;
  background: #e01811;
}

.pink {
  color: #e01811;
  background: #ffbebc;
}

.btn-item.active {
  color: #e01811;
  background: #ffbebc;
}

.rigth-paging {
  flex: 1;
  height: 100%;
  padding: 32px;
  background: #ffffff;
  box-sizing: border-box;
}

.contact-title {
  margin: 0px;
  font-size: 24px;
  font-weight: 600;
  color: #e01811;
}

.search-box {
  width: 100%;
  height: 56px;
  display: flex;
  background: #f5f5f5;
  margin-top: 16px;
  margin-bottom: 40px;
}

.input-btn {
  height: 100%;
  color: #333333;
  font-size: 18px;
  font-weight: 400;
  outline: none;
  background: none;
  border: none;
  flex: 1;
  margin-left: 32px;
}

.search-btn {
  width: 131px;
  height: 100%;
  color: #ffffff;
  font-size: 22px;
  font-weight: 500;
  background: #e01811;
  text-align: center;
  line-height: 56px;
  cursor: pointer;
}
</style>